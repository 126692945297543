import CloseIcon from '@mui/icons-material/Close';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import IconButton from '@mui/material/IconButton';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import { Box, styled } from '@mui/system';
import { DataGrid, GridColDef, GridRenderCellParams, GridSelectionModel, GridToolbarContainer, GridToolbarQuickFilter } from '@mui/x-data-grid';
import React, { useEffect } from 'react';
import { getCategoryIcon } from "../../utils/icons";
import { useModelInputOptionsMetricQuery, useModelInputOptionsMappingQuery, useUpdateUserModelInputsMetric, useUpdateUserModelInputsGlobal, IModelInput } from "../../api/models";
import { useErrorMessageAlert } from "../../components/Errors/useErrorMessageAlert";
import { AxiosError } from 'axios';
import LinearProgress from '@mui/material/LinearProgress';
import { UseQueryResult } from '@tanstack/react-query';
import Switch from '@mui/material/Switch';
import FormControlLabel from '@mui/material/FormControlLabel';

const FeatureSelectDataGrid = styled(DataGrid)(({ theme }) => ({

    '& .MuiDataGrid-cell:focus-within': {
        outline: 'none'
    }
}))


function getCategoryIconComponent(categoryName: string) {
    const Icon = getCategoryIcon(categoryName);
    return <Icon color="primary" />;

}

function renderCategoryCell(params: GridRenderCellParams) {

    return (
        <Stack direction="row" spacing={2}>
            {getCategoryIconComponent(params.value)}
            <Typography>{params.value}</Typography>
        </Stack>
    )

}



const columns: GridColDef[] = [
    { field: 'vendor_name', headerName: 'Vendor', flex: 1 },
    { field: 'title', headerName: 'Data Field', flex: 1 },
    { field: 'name', headerName: 'Field ID', flex: 1 },
    { field: 'data_type', headerName: 'Data Type', flex: 1, renderCell: renderCategoryCell },
];

function sameMembers(arr1: number[], arr2: number[]) {
    const set1 = new Set(arr1);
    const set2 = new Set(arr2);
    return arr1.every(item => set2.has(item)) &&
        arr2.every(item => set1.has(item))
}


function CustomToolbar() {
    return (
        <GridToolbarContainer sx={{ mb: 1 }}>
            <GridToolbarQuickFilter
                debounceMs={500}
                // variant="outlined"
                placeholder="Search all datasets ..."
                // fullWidth={true}
                sx={{ width: "99%" }}
                autoComplete='off'
            />

        </GridToolbarContainer>
    );
}



function ModelFeaturesAddDialog(props: {
    titleHeader: string,
    isOpen: boolean,
    modelInputOptionsQuery: UseQueryResult<IModelInput[]>,
    updateUserModelInputs: ReturnType<typeof useUpdateUserModelInputsMetric> | ReturnType<typeof useUpdateUserModelInputsGlobal>,
    handleClose: ((event: {}, reason: string) => void),
    additionalNotes?: string
}) {

    // const [datasets, setDatasets] = useState<IDataset[]>([]);
    const { setErrorMessage, ErrorMessageAlert, setErrorMessageFromResponse } = useErrorMessageAlert();
    const [selectionModel, setSelectionModel] = React.useState<GridSelectionModel>([]);

    // sync current selection with initial data table
    useEffect(() => {
        if (props.modelInputOptionsQuery.data) {
            setSelectionModel(props.modelInputOptionsQuery?.data.filter((row) => row.is_selected === true).map((row) => row.id));
        }
    }, [props.modelInputOptionsQuery.data]);

    function submitAddDatasets(event: {}, reason: string) {

        setErrorMessage("");

        if (selectionModel.length === 0) {
            setErrorMessage("No datasets selected.");
            return;
        }

        if (props.modelInputOptionsQuery.data === undefined) {
            return;
        }

        const data_field_ids = selectionModel as number[];

        // if selection model hasnt changed, then dont submit
        const prevSelection = props.modelInputOptionsQuery?.data.filter((row) => row.is_selected === true).map((row) => row.id);

        if (sameMembers(data_field_ids, prevSelection)) {
            props.handleClose(event, reason);
            return;
        }

        const is_not_selected = props.modelInputOptionsQuery.data.filter((row: IModelInput) => !data_field_ids.includes(row.id));

        // create array of objects as {field_id: 1, is_selected: true}
        const input_updates = data_field_ids.map((id: number) => {
            return {
                field_id: id,
                is_selected: true
            }
        }).concat(is_not_selected.map((row) => {
            return {
                field_id: row.id,
                is_selected: false
            }
        }));

        props.updateUserModelInputs.mutate(input_updates, {
            onSuccess: () => { props.handleClose(event, reason) },
            onError: (error: any | AxiosError) => setErrorMessageFromResponse(error)

        });

    }

    return (
        <>
            <Dialog open={props.isOpen} onClose={props.handleClose} fullWidth maxWidth='lg'

                PaperProps={{
                    sx: {
                        // width: "50%",
                        // maxHeight: 300
                        minHeight: 750,
                        backgroundImage: 'none'
                        // backgroundColor: 'text.primary'
                    }
                }}
            >
                <DialogTitle>
                    <Box>
                        {props.titleHeader}

                        <Typography variant="body2" color="text.secondary" sx={{ mt: 1 }}>
                            Check/uncheck datasets to add or remove them from the combined forecast model.
                            {props.additionalNotes && " " + props.additionalNotes}
                        </Typography>

                    </Box>
                    <IconButton
                        aria-label="close"
                        onClick={(event) => props.handleClose(event, 'buttonClose')}
                        sx={{
                            position: 'absolute',
                            right: 8,
                            top: 8,
                            color: (theme) => theme.palette.grey[500],
                        }}
                    >
                        <CloseIcon />
                    </IconButton>

                </DialogTitle>


                <DialogContent>

                    <Box sx={{ display: 'flex', height: 600 }}>
                        <div style={{ flexGrow: 1 }}>

                            <FeatureSelectDataGrid
                                loading={props.modelInputOptionsQuery.isLoading}
                                checkboxSelection
                                rows={props.modelInputOptionsQuery.data || []}
                                columns={columns}
                                pageSize={100}
                                rowsPerPageOptions={[100]}
                                onSelectionModelChange={(newSelectionModel) => {
                                    setSelectionModel(newSelectionModel);
                                }}
                                selectionModel={selectionModel}
                                components={{ Toolbar: CustomToolbar }}
                                density="compact"
                            />


                        </div>
                    </Box>

                </DialogContent>

                <DialogActions>
                    {(props.updateUserModelInputs.isLoading) ?

                        <Box sx={{ width: '100%' }}>
                            <LinearProgress />
                        </Box>
                        :
                        <>
                            <Button sx={{ width: 100 }} variant="outlined" onClick={(event) => { props.handleClose(event, "buttonCancel"); setErrorMessage(""); }}>Cancel</Button>
                            <Button sx={{ width: 100 }} variant="contained" onClick={(event) => { submitAddDatasets(event, "buttonSubmit"); }}>Save</Button>
                        </>
                    }
                </DialogActions>

            </Dialog >

            {ErrorMessageAlert}

        </>

    );

}









export function ModelFeaturesAddDialogMetric(props: {
    metric_id: number,
    metricName: string,
    companySymbol: string,
    isOpen: boolean,
    handleClose: ((event: {}, reason: string) => void)
}) {

    const modelInputOptionsQuery = useModelInputOptionsMetricQuery(props.metric_id);
    const updateUserModelInputs = useUpdateUserModelInputsMetric(props.metric_id);

    return (
        <ModelFeaturesAddDialog
            titleHeader={`Update datasets for ${props.companySymbol}: ${props.metricName}`}
            isOpen={props.isOpen}
            modelInputOptionsQuery={modelInputOptionsQuery}
            updateUserModelInputs={updateUserModelInputs}
            handleClose={props.handleClose}
        />
    )
}

export function ModelFeaturesAddDialogMapping(props: {
    model_map_id: number,
    isOpen: boolean,
    handleClose: ((event: {}, reason: string) => void)
}) {

    const modelInputOptionsQuery = useModelInputOptionsMappingQuery(props.model_map_id);
    const updateUserModelInputs = useUpdateUserModelInputsGlobal();

    return (
        <ModelFeaturesAddDialog
            titleHeader={`Update default datasets to include in combined forecast`}
            isOpen={props.isOpen}
            modelInputOptionsQuery={modelInputOptionsQuery}
            updateUserModelInputs={updateUserModelInputs}
            handleClose={props.handleClose}
            additionalNotes='You can still override these datasets for each company metric.'
        />
    )
}
