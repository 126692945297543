import { green, red } from '@mui/material/colors';
import { Box, styled } from '@mui/system';
import React, { useState } from 'react';
import Typography from '@mui/material/Typography';
import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';
import {
    DataGrid,
    GridColumns,
    GridRenderCellParams,
    GridToolbarColumnsButton,
    GridToolbarContainer,
    GridToolbarExport,
    GridToolbarQuickFilter,
} from '@mui/x-data-grid';
import SparkLine from '../Charts/SparkLine'
import ViewTableFormatDialog from "./ViewTableFormatDialog";
import { ICellHighlightParams, ICellChartParams, ChartType } from "../../interfaces"
import { useQuery, useQueryClient } from '@tanstack/react-query'
import { useViewTableDataQuery, IViewInput } from '../../api/views';
import LinearProgress from '@mui/material/LinearProgress';
import { DataInputDisplayName } from "../../utils/dataInput";

const nonDataCols = ["Period", "Ticker"];


// generic key: value records dataset
interface ITableData {
    [key: string]: string | number;
}

type ResponseData = { rows: ITableData[], columns: GridColumns<ITableData> };


const ViewDataGrid = styled(DataGrid)(({ theme }) => ({

    fontWeight: 500,
    fontSize: 14,

    '& .negative': {
        color: red[400],
    },

    '& .positive': {
        color: green[400],
    },

    '& .negative-outlier': {
        backgroundColor: red[400],
        color: "white"
    },

    '& .positive-outlier': {
        backgroundColor: green[400],
        color: "white"
    },

    '& .sparkline-cell': {
        padding: "3px 3px",
        border: "1px solid",
        borderColor: theme.palette.divider,
        borderRadius: 2
    },

    // '& .MuiDataGrid-columnHeaderTitle': {
    //     textOverflow: "clip",
    //     whiteSpace: "break-spaces",
    //     lineHeight: 1
    // }


}))


function renderTickerCell(params: GridRenderCellParams) {
    return (
        <Typography sx={{ fontWeight: 400, fontSize: 14 }} color={"text.primary"} >{params.value}
        </Typography>
    );
}

function randomIntFromInterval() { // min and max included
    return Math.floor(Math.random() * (5 - 1 + 1) + 1)
}

function randomData(n = 30) {
    return Array.apply(0, Array(n)).map(randomIntFromInterval);
}

function SparkLinesCell(params: GridRenderCellParams) {
    return <SparkLine color="#90caf9" chartType="line" labels={randomData(12).map((i) => String(i))} data={randomData(12)} />;
}

function SparkBarCell(params: GridRenderCellParams) {
    return <SparkLine color="#90caf9" chartType="bar" labels={randomData(12).map((i) => String(i))} data={randomData(12)} />;
}

const MemoizedSparkLinesCell = React.memo(SparkLinesCell);
const MemoizedSparkBarCell = React.memo(SparkBarCell);

function renderSparkLineCell(params: GridRenderCellParams<number>, chartParams: ICellChartParams) {
    if (chartParams.chart_type === "bar") {
        return <MemoizedSparkBarCell {...params} />;
    } else {
        return <MemoizedSparkLinesCell {...params} />;
    }
}


function numberCellClassName(params: GridRenderCellParams, highlightParams: ICellHighlightParams) {
    let className = '';

    if (highlightParams.upper_outlier !== null && highlightParams.upper_outlier !== undefined && (params.value > parseFloat(highlightParams.upper_outlier))) {
        className = "positive-outlier";
    } else if (highlightParams.upper !== null && highlightParams.upper !== undefined && (params.value > parseFloat(highlightParams.upper))) {
        className = "positive";
    } else if (highlightParams.lower_outlier !== null && highlightParams.lower_outlier !== undefined && (params.value < parseFloat(highlightParams.lower_outlier))) {
        className = "negative-outlier";
    } else if (highlightParams.lower !== null && highlightParams.lower !== undefined && (params.value < parseFloat(highlightParams.lower))) {
        className = "negative";
    }

    return className;
}


function getRenderCell(params: GridRenderCellParams, formatType: string | null, formatParams: any | null) {

    if (params.value === undefined || params.value === null) {
        return <></>;
    }

    if (params.field === "Ticker") {
        return renderTickerCell(params);
    }

    if (formatType === "chart") {
        return renderSparkLineCell(params, formatParams as ICellChartParams);
    }

    return <div>{params.value}</div>;
}


function getCellClass(params: GridRenderCellParams, formatType: string | null, formatParams: any | null) {

    if (nonDataCols.indexOf(params.field) !== -1) {
        return undefined;
    }

    if (formatType === "chart") {
        return "sparkline-cell";
    }

    if (formatType === "highlight") {
        return numberCellClassName(params, formatParams as ICellHighlightParams);
    }

    return undefined;

}



function renderMultiLineHeader(params: GridRenderCellParams) {

    if (!params.colDef.headerName || params.colDef.headerName.length === 0) {
        return <></>;
    }


    return <DataInputDisplayName name={params.colDef.headerName} />;
}


function CustomToolbar() {
    return (
        <GridToolbarContainer sx={{ mb: 1 }}>
            <GridToolbarQuickFilter
                debounceMs={500}
                // variant="outlined"
                placeholder="Search all tickers and metrics ..."
                // fullWidth={true}
                sx={{ width: "40%" }}
                autoComplete='off'
            />
            <Box sx={{ marginLeft: "auto" }}>
                {/* <GridToolbarColumnsButton /> */}
                {/* <GridToolbarFilterButton /> */}
                {/* <GridToolbarDensitySelector /> */}
                <GridToolbarExport printOptions={{ disableToolbarButton: true }} />
            </Box>

        </GridToolbarContainer>
    );
}

export default function ViewTable(props: {
    view_id: number,
    selectedInputs: IViewInput[],
    title?: string,
    ToolbarComponent?: JSX.Element,
    compact?: boolean
}) {

    const tableDataQuery = useViewTableDataQuery(props.view_id);
    const queryClient = useQueryClient()

    const [formatCellDialogOpen, setFormatCellDialogOpen] = useState<boolean>(false);
    const [formatCellLoading, setFormatCellLoading] = useState<boolean>(false);

    const tableColumns = tableDataQuery.data?.columns?.map((x: any, i: any) => ({
        ...x,
        maxWidth: nonDataCols.indexOf(x.field) !== -1 ? 160 : 200,
        width: x.format === "chart" ? 200 : undefined,
        flex: 1,
        renderCell: (params: GridRenderCellParams) => getRenderCell(params, x.format, x.format_params),
        cellClassName: (params: GridRenderCellParams) => getCellClass(params, x.format, x.format_params),
        // disableColumnMenu: true,
        filterable: true,
        renderHeader: renderMultiLineHeader,

    }));

    if (tableDataQuery.data?.rows && tableDataQuery.data?.rows?.length > 0 && tableColumns) {
        return (
            <Box
                sx={{ mr: 3, height: "94%" }}>

                <Stack direction={"row"}>
                    <Box m={1}
                        display="flex"
                        flexGrow={1}>

                        {props.title && <Typography>{props.title}</Typography>}

                    </Box>

                    <Box m={1}>
                        {props.selectedInputs.length > 0 && <Button onClick={() => setFormatCellDialogOpen(true)}>FORMAT CELLS</Button>}
                    </Box>

                    {props.ToolbarComponent && props.ToolbarComponent}

                </Stack>

                <ViewDataGrid
                    // autoHeight
                    hideFooter
                    hideFooterSelectedRowCount
                    rows={tableDataQuery.data?.rows}
                    columns={tableColumns}
                    // hideFooter={true}
                    // autoPageSize={true}
                    density="compact"
                    loading={tableDataQuery.isLoading || tableDataQuery.isFetching || formatCellLoading}
                    components={props.compact ? undefined : { Toolbar: CustomToolbar }}
                    headerHeight={80}
                    sx={{
                        '& .MuiDataGrid-cell:focus-within': {
                            outline: 'none'
                        },
                        height: "100%"

                    }}
                    initialState={{
                        columns: {
                            columnVisibilityModel: {
                                // Hide columns status and traderName, the other columns will remain visible
                                id: false,
                                // Period: false
                            },
                        },
                    }}

                />

                {props.selectedInputs.length > 0 &&
                    <ViewTableFormatDialog
                        datasets={props.selectedInputs}
                        handleClose={() => setFormatCellDialogOpen(false)}
                        isOpen={formatCellDialogOpen}
                        onAddSuccess={() => {
                            queryClient.invalidateQueries(['views_table_data', props.view_id]);
                            queryClient.invalidateQueries(['views_table_options', props.view_id]);
                        }}
                        setLoading={setFormatCellLoading}
                    />
                }


            </Box>
        );

    }

    else if (tableDataQuery.data?.rows && tableDataQuery.data?.rows?.length === 0) {
        return (
            <Box sx={{ padding: 4 }}>
                <Typography color="text.secondary">No data available</Typography>
            </Box>
        );
    }

    else {
        return (
            <Box sx={{ width: '100%' }}>
                <LinearProgress />
            </Box>
        );
    }

}
