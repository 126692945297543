import MenuItem from '@mui/material/MenuItem';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import { Box } from '@mui/system';
import LinearProgress from '@mui/material/LinearProgress';
import React, { useState, useEffect } from 'react';
import { useSearchParams, useParams, Navigate, useNavigate } from 'react-router-dom';
import { DataGrid, GridColDef, GridRenderCellParams, GridToolbarContainer, GridToolbarQuickFilter, GridColumnHeaderParams } from '@mui/x-data-grid';
import { useScreenerDataQuery, useScreenerQuery, useRemoveScreener, useScreenersQuery, useAddScreenerInput, useAddScreenerWatchlist, useRemoveScreenerWatchlist } from "../api/screener"
import { useWatchlistsQuery } from "../api/watchlist"
import { green, red, grey } from '@mui/material/colors';
import ScreenerRemoveFactorDialog from "../components/Screener/ScreenerRemoveFactorDialog"
import ScreenerAddDataDialog from "../components/Screener/ScreenerAddDataDialog"
import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import IconButton from '@mui/material/IconButton';
import Menu from '@mui/material/Menu';
import Divider from '@mui/material/Divider';
import SettingsIcon from '@mui/icons-material/Settings';
import { INewAlpha } from '../api/alphas';
import { useErrorMessageAlert } from "../components/Errors/useErrorMessageAlert"
import { AxiosError } from 'axios'
import { DataInputDisplayName } from "../utils/dataInput";


function ValueCell(props: { value: number, displayValue: number, valueType: "zscore" | "rank" | "raw" }) {

	if (props.value === undefined || props.value === null || props.displayValue === undefined || props.displayValue === null) {
		return <></>;
	}

	let color: string = grey[500];

	if (props.valueType === "rank") {

		if (props.value >= 4) {
			color = green[500];
		} else if (props.value >= 1) {
			color = green[300];
		} else if (props.value <= -4) {
			color = red[500];
		} else if (props.value <= -1) {
			color = red[300];
		} else {
			color = grey[400];
		}

	} else if (props.valueType === "zscore") {
		if (props.value >= 2) {
			color = green[500];
		}
		else if (props.value >= 1) {
			color = green[300];
		}
		else if (props.value >= 0.25) {
			color = green[200];
		}

		else if (props.value <= -2) {
			color = red[500];
		}
		else if (props.value <= -1) {
			color = red[300];
		}
		else if (props.value <= -0.25) {
			color = red[200];
		}

		else {
			color = grey[400];
		}
	}


	return (
		<Box sx={{
			lineHeight: "32px",
			backgroundColor: color,
			width: "100%",
			// height: "100%",
			borderRadius: 0.5,
			paddingLeft: 1,
			paddingRight: 1,
			paddingTop: 0.4,
			paddingBottom: 0.4,
			display: "flex",
			justifyContent: "center",
			alignItems: "center",
			fontWeight: 700,
			fontSize: 16,
			color: grey[900],

		}}>{props.displayValue}</Box>
	);

}


function renderRankCell(params: GridRenderCellParams) {

	if (params.value.value_rank === undefined || params.value.value_rank === null) {
		return <></>;
	}
	return <ValueCell valueType='rank' value={params.value.value_rank as number} displayValue={params.value.value_rank as number} />
}

function renderZScoreCell(params: GridRenderCellParams) {

	if (params.value.value_zs === undefined || params.value.value_zs === null) {
		return <></>;
	}
	return <ValueCell valueType="zscore" value={params.value.value_zs as number} displayValue={params.value.value_zs.toFixed(1) as number} />
}
function renderRawCell(params: GridRenderCellParams) {

	if (params.value === undefined || params.value === null) {
		return <></>;
	}
	return <ValueCell valueType="zscore" value={params.value.value_zs as number} displayValue={params.value.value_raw as number} />
}


function renderStringCell(params: GridRenderCellParams) {

	if (params.value === undefined || params.value === null) {
		return <></>;
	}

	return (
		<Typography sx={{
			lineHeight: "32px",
			fontWeight: 400,
			fontSize: 15,
		}}>{params.value}</Typography>
	);
}




function CustomToolbar() {
	return (
		<GridToolbarContainer sx={{ mb: 1 }}>
			<GridToolbarQuickFilter
				debounceMs={500}
				// variant="outlined"
				placeholder="Search all tickers ..."
				// fullWidth={true}
				sx={{ width: "40%" }}
				autoComplete='off'

			/>
			<Box sx={{ marginLeft: "auto" }}>
				{/* <GridToolbarColumnsButton /> */}
				{/* <GridToolbarFilterButton /> */}
				{/* <GridToolbarDensitySelector /> */}
				{/* <GridToolbarExport printOptions={{ disableToolbarButton: true }} /> */}
			</Box>

		</GridToolbarContainer>
	);
}



function SettingsButton(props: {
	screener_id: number,
	openRemoveDialog: () => void
}) {


	const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
	const open = Boolean(anchorEl);
	const removeScreener = useRemoveScreener();
	const navigate = useNavigate();

	const screenersQuery = useScreenersQuery(); // list of all screeners. Used to get screener to reidrect to after delete

	if (screenersQuery.isLoading || screenersQuery.isFetching) {
		return <></>;
	}

	const otherScreeners = screenersQuery.data?.filter((x) => x.id !== props.screener_id) || [];

	return (
		<>
			<IconButton
				sx={{ ml: 1 }}
				onClick={(event: React.MouseEvent<HTMLButtonElement>) => setAnchorEl(event.currentTarget)}
				aria-haspopup="true">
				<SettingsIcon color="disabled" />
			</IconButton>

			<Menu
				open={open}
				onClose={() => setAnchorEl(null)}
				anchorEl={anchorEl}
			>
				<Box>
					<MenuItem
						onClick={() => {
							props.openRemoveDialog();
							setAnchorEl(null);
						}}
					>
						Remove Factors
					</MenuItem>
					<Divider />

					<MenuItem
						onClick={() => {

							if (otherScreeners.length > 0) {
								removeScreener.mutate(props.screener_id, { onSuccess: () => navigate(`/screener/${otherScreeners[0].id}`) });
							} else {
								removeScreener.mutate(props.screener_id, { onSuccess: () => navigate(`/`) });

							}
							setAnchorEl(null);

						}}
					>
						Delete Screener
					</MenuItem>


				</Box>
			</Menu>
		</>

	)
}




export default function Screener() {

	const [searchParams, setSearchParams] = useSearchParams(); // ex: period comp/max period settings
	const valueType: string = searchParams.get("valueType") || "rank";  // default to rank view

	const urlParams = useParams(); // strategy id
	const screener_id = parseInt(urlParams.screener_id !== undefined ? urlParams.screener_id : "0");
	const screenerQuery = useScreenerQuery(screener_id); // screener name and optional selected watchlist

	// const watchlist_id: number = parseInt(searchParams.get("watchlist") || "0");  // default to 0 (all securities)
	const watchlist_id = screenerQuery.data?.watchlist_id || 0;

	const dataQuery = useScreenerDataQuery(screener_id, watchlist_id, screenerQuery.isSuccess);
	const watchlistsQuery = useWatchlistsQuery();
	const addScreenerWatchlist = useAddScreenerWatchlist(screener_id);
	const removeScreenerWatchlist = useRemoveScreenerWatchlist(screener_id);

	const [isRemoveDialogOpen, setIsRemoveDialogOpen] = useState(false);

	const hasData = dataQuery.data?.rows?.length || 0 > 0;

	const [isAddDialogOpen, setIsAddDialogOpen] = useState(false);

	function renderMultiLineHeader(params: GridColumnHeaderParams) {

		if (!params.colDef.headerName || params.colDef.headerName.length === 0) {
			return <></>;
		}

		return <DataInputDisplayName name={params.colDef.headerName} />;
	}


	if (dataQuery.isLoading) {
		return (
			<Box sx={{ width: '100%' }}>
				<LinearProgress />
			</Box>
		);
	}


	const gridColumns: GridColDef[] = dataQuery.data?.columns.map((x, i) => ({
		...x,
		disableColumnMenu: true,
		filterable: false,
		flex: 1,
		sortable: true,

		// only quickfilter on symbol column
		...(x.field !== "symbol" && { getApplyQuickFilterFn: undefined }),

		renderHeader: renderMultiLineHeader,

		renderCell: (x.type === "string") ? renderStringCell :
			(valueType === "rank" ? renderRankCell :
				valueType === "zscore" ? renderZScoreCell :
					(x.field === "__ARB_SCORE") ? renderRankCell :
						renderRawCell

			),
		sortComparator: (v1: any, v2: any) => {
			if (x.type === "string") {
				return (v1 as string).localeCompare(v2 as string);
			}

			if (valueType === "rank") return v1.value_rank - v2.value_rank;
			if (valueType === "zscore") return v1.value_zs - v2.value_zs;
			if (valueType === "raw" && x.field === "__ARB_SCORE") return v1.value_rank - v2.value_rank;
			if (valueType === "raw") return v1.value_raw - v2.value_raw;

			return v1 - v2;
		},

	})) || [];



	return (

		<>
			<Box sx={{
				height: "85vh",

				marginTop: 2,
				marginLeft: 2,
				marginRight: 2,

			}}>

				<Stack direction="row"
					alignContent={"center"}
					justifyContent={"space-between"}
					sx={{
						mt: 1.5,
						mb: 2.0,
						padding: (theme) => theme.spacing(1.5),
						backgroundColor: (theme) => theme.palette.card.background,
						border: "1px solid",
						borderColor: (theme) => theme.palette.divider,
						borderRadius: 1


					}}


				>
					<Box sx={{ flexGrow: 1, display: 'flex', alignItems: "center" }}>

						<Typography sx={{ mr: 2 }} variant="h5">{screenerQuery.data?.name || ""}</Typography>


					</Box>


					<Stack direction={"row"}>

						<Select
							size="small"
							value={String(watchlist_id)}
							onChange={(event: SelectChangeEvent) => {

								if (parseInt(event.target.value) === 0) {
									removeScreenerWatchlist.mutate();
									return;
								}

								addScreenerWatchlist.mutate(parseInt(event.target.value));
							}}
						>
							<MenuItem value={"0"}>All Securities</MenuItem>

							{watchlistsQuery.data?.map((x) => (
								<MenuItem key={String(x.id)} value={String(x.id)}>{x.name}</MenuItem>
							))}


						</Select>

						<ToggleButtonGroup
							size="small"
							color="primary"
							value={valueType}
							exclusive
							onChange={(event: React.MouseEvent<HTMLElement>, newValue: string) => {

								if (newValue && newValue !== valueType) {
									searchParams.set("valueType", newValue);
									setSearchParams(searchParams);
								}

							}
							}
							sx={{ mr: 2, ml: 2 }}

						>
							<ToggleButton sx={{ width: 80 }} value="rank">Rank</ToggleButton>
							<ToggleButton sx={{ width: 80 }} value="zscore">ZScore</ToggleButton>
							<ToggleButton sx={{ width: 80 }} value="raw">Raw</ToggleButton>
						</ToggleButtonGroup>

						<Button variant="outlined" color="info" onClick={() => setIsAddDialogOpen(true)}  >
							Add Data
						</Button>

						<SettingsButton screener_id={screener_id}
							openRemoveDialog={() => setIsRemoveDialogOpen(true)}
						/>




					</Stack>


				</Stack>

				<Box
					height="100%"
					sx={{

						// padding: 25,
						// marginTop: 3,
						// marginBottom: 3,
						// marginLeft: 4,
						// marginRight: 4
					}}
				>

					{!hasData ?
						<Box
							sx={{
								display: "flex",
								flexDirection: "column",
								justifyContent: "center",
								alignItems: "center",
								height: "100%",

							}}
						>
							<Typography variant="h6" sx={{ textAlign: "center", color: (theme) => theme.palette.warning.light, opacity: 0.8 }}>Add a data field to build a Screener</Typography>

						</Box>
						:

						(dataQuery.isLoading || dataQuery.isFetching) ?
							<Box sx={{ width: '100%' }}>
								<LinearProgress />
							</Box>
							:

							<DataGrid
								rows={dataQuery.data?.rows || []}
								columns={gridColumns}
								loading={dataQuery.isLoading || dataQuery.isFetching}
								hideFooter={true}
								disableSelectionOnClick={true}
								density={"standard"}
								pagination
								components={{ Toolbar: CustomToolbar }}
								sx={{
									height: "100%",
									'& .MuiDataGrid-cell:focus-within': {
										outline: 'none'
									},
									backgroundColor: (theme) => theme.palette.card.background,
									// borderRadius: 2,

								}}
								initialState={{
									pagination: {
										pageSize: 100
									},

								}}


							/>
					}

				</Box >


			</Box>


			<ScreenerRemoveFactorDialog screener_id={screener_id} isOpen={isRemoveDialogOpen} handleClose={() => setIsRemoveDialogOpen(false)} />
			<ScreenerAddDataDialog screener_id={screener_id} isOpen={isAddDialogOpen} handleClose={() => setIsAddDialogOpen(false)} />

		</>

	);

};
