import DashboardIcon from '@mui/icons-material/Dashboard';
import Button from '@mui/material/Button';
import { green, red, grey, blue, orange } from '@mui/material/colors';
import IconButton from '@mui/material/IconButton';
import Stack from '@mui/material/Stack';
import Tooltip from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import { DataGrid, GridColDef, GridRenderCellParams, GridToolbar, GridToolbarColumnsButton, GridToolbarContainer, GridToolbarDensitySelector, GridToolbarExport, GridToolbarFilterButton, GridToolbarQuickFilter } from '@mui/x-data-grid';
import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { useModelEstimateSummaryQuery, useModelCompanyPeriodsQuery } from "../../api/models"
import { renderProgressCell, renderScoreCell, renderHighlightPercentCell } from "../Tables/renderCellFunctions"
import TrendingUpIcon from '@mui/icons-material/TrendingUp';
import TrendingDownIcon from '@mui/icons-material/TrendingDown';
import LinearScaleIcon from '@mui/icons-material/LinearScale';
import { alpha, useTheme } from '@mui/system';
import { DialogChart, DataChart } from '../../components'
import ShowChartIcon from '@mui/icons-material/ShowChart';
import { getModelChartColor } from "../../components/Charts/utils";



function renderPercentCell(params: GridRenderCellParams) {

	if (params.value === undefined || params.value === null) {
		return <></>;
	}

	return (
		<>{params.value.toFixed(1) + " %"}</>
	);

}
function renderCorrCell(params: GridRenderCellParams) {

	if (params.value === undefined || params.value === null) {
		return <></>;
	}

	let color: string = "text.secondary";

	if (params.value > 0.8) {
		color = green[500];
	} else if (params.value > 0.5) {
		color = green[200];
	} else if (params.value < -0.8) {
		color = red[500];
	} else if (params.value < -0.5) {
		color = red[200];
	}

	return (
		<Typography fontSize={13} color={color}  >{params.value.toFixed(2)}
		</Typography>
	);

}

function renderBeatMissCell(params: GridRenderCellParams) {

	if (params.value === undefined || params.value === null) {
		return <></>;
	}

	let color: string = "text.secondary";

	if (params.value > 70) {
		color = green[500];
	} else if (params.value > 50) {
		color = green[200];
	} else if (params.value < 30) {
		color = red[500];
	} else if (params.value < 50) {
		color = red[200];
	}

	return (
		<Typography fontSize={13} color={color}  >{params.value.toFixed(0) + '%'}
		</Typography>
	);

}

function renderMetricImpCell(params: GridRenderCellParams, compact: boolean = false) {

	if (params.value === undefined || params.value === null) {
		return <></>;
	}

	let color: string = orange[500];
	let text: string = 'MED';

	if (params.value > 66) {
		color = '#4caf50';
		text = 'HIGH';
	} else if (params.value < 33) {
		color = '#ef5350';
		text = 'LOW';
	}

	return (
		<Tooltip title={`Sensitivity Score: ${params.value}`}>

			<Box sx={{
				// lineHeight: "24px",
				backgroundColor: alpha(color, 0.50),
				width: "100%",
				borderRadius: 0.5,
				paddingLeft: 1,
				paddingRight: 1,
				paddingTop: 0.4,
				paddingBottom: 0.4,
				display: "flex",
				justifyContent: "center",

			}}>

				<Typography fontWeight={500} fontSize={compact ? 13 : 13}>{text}</Typography>
			</Box>
		</Tooltip>
	);

}

function renderArrowValueCell(params: GridRenderCellParams, compact: boolean = false) {

	if (params.value === undefined || params.value === null) {
		return <></>;
	}

	let color: string = grey[500];
	let Icon: JSX.Element = <LinearScaleIcon sx={{ color: color }} />;

	// when compact, dont show icon

	if (params.value > 0) {
		color = green[400];
		Icon = <TrendingUpIcon sx={{ color: color }} />
	} else if (params.value < 0) {
		color = red[400];
		Icon = <TrendingDownIcon sx={{ color: color }} />
	}

	// if (compact) {
	// 	Icon = <></>
	// }


	return (

		<Stack direction="row" alignItems={"center"} alignContent={"center"} justifyContent={"center"}
			spacing={1} sx={{
				backgroundColor: alpha(color, 0.20),
				borderRadius: 1,
				paddingLeft: 1,
				paddingRight: 1,
				paddingTop: 0.4,
				paddingBottom: 0.4,
				width: "100%",
			}}>
			{/* / when compact, dont show icon */}
			{!compact && Icon}
			<Typography sx={{ color: color, fontWeight: 600, fontSize: 12 }}  >{params.value.toFixed(2) + " %"}
			</Typography>

		</Stack>
	);

}




function CustomToolbar() {
	return (
		<GridToolbarContainer sx={{ mb: 1 }}>
			<GridToolbarQuickFilter
				debounceMs={500}
				// variant="outlined"
				placeholder="Search all tickers and metrics ..."
				// fullWidth={true}
				sx={{ width: "40%" }}
				autoComplete='off'
			/>
			<Box sx={{ marginLeft: "auto" }}>
				<GridToolbarColumnsButton />
				{/* <GridToolbarFilterButton /> */}
				{/* <GridToolbarDensitySelector /> */}
				<GridToolbarExport printOptions={{ disableToolbarButton: true }} />
			</Box>

		</GridToolbarContainer>
	);
}


export default function ModelResultsTable(
	props: {
		density?: "compact" | "standard" | "comfortable",
		title?: string,
		ToolbarComponent?: JSX.Element,
		field_id?: number,
		model_family_id?: number,
		watchlist_id?: number,

	}) {

	const field_id = props.field_id || 0;
	const model_family_id = props.model_family_id || 0;
	const watchlist_id = props.watchlist_id || 0;

	const [histDialogShow, setHistDialogShow] = useState<boolean>(false);
	const [histDialogSelection, setHistDialogSelection] = useState<{ metricId: number, metricName: string, symbol: string, fs_period: string, period_id: number } | null>(null);

	const modelEstimateSummaryQuery = useModelEstimateSummaryQuery(field_id, model_family_id, watchlist_id);
	const modelCompanyPeriodsQuery = useModelCompanyPeriodsQuery(histDialogSelection?.metricId || 0);

	function renderPreviewButton(params: GridRenderCellParams) {
		return (
			// <Tooltip title="Show estimate history" >
			<IconButton onClick={() => {
				setHistDialogSelection({
					metricId: params.row.metric_id, metricName: params.row.metric, symbol: params.row.symbol,
					fs_period: params.row.fs_period, period_id: params.row.period_id
				});
				setHistDialogShow(true);
			}} color="primary" ><ShowChartIcon /></IconButton>
			// </Tooltip>
		);
	}

	function renderSymbolCell(params: GridRenderCellParams) {

		// Name links to Model/Company drilldown page. Pass in URL param with Ids

		return (
			// <Tooltip title="Model Ticker Drilldown" placement="top-start">
			<Button
				size="large"
				color="primary"
				component={Link}
				to={`/company/${params.row.company_id}?metric=${params.row.metric_id}&data=${field_id}&model_family=${model_family_id}&display=forecasts`}
				variant="text">
				{params.value}
			</Button>
			// </Tooltip>
		)

	}

	const columns: GridColDef[] = [

		// reference columns
		{ field: 'symbol', headerName: 'Symbol', flex: 1, disableColumnMenu: true, filterable: false, renderCell: renderSymbolCell },
		{ field: 'company', headerName: 'Company', flex: 1, disableColumnMenu: true, filterable: false },
		// { field: 'model_name', headerName: 'Model', flex: 1, disableColumnMenu: true, filterable: true },
		{ field: 'metric', headerName: 'Metric', flex: 2, disableColumnMenu: true, filterable: false },
		{ field: 'fs_period', headerName: 'Period', flex: 1, disableColumnMenu: true, filterable: false },
		// { field: 'data_date', headerName: 'Date Updated', flex: 1, disableColumnMenu: true, filterable: false },
		{ field: 'period_end', headerName: 'Period End', flex: 1, disableColumnMenu: true, filterable: false },
		{ field: 'earnings_date', headerName: 'Earnings Date', flex: 1, disableColumnMenu: true, filterable: false },

		// { field: 'sector', headerName: 'Sector', flex: 1, disableColumnMenu: true, filterable: false },
		// { field: 'industry', headerName: 'Industry', flex: 1, disableColumnMenu: true, filterable: false },

		// model output columns
		{ field: 'model_estimate', headerName: 'Model Est.', flex: 1, disableColumnMenu: true, filterable: false, type: 'number' },
		{ field: 'consensus', headerName: 'Consensus', flex: 1, disableColumnMenu: true, filterable: false, type: 'number' },
		// { field: 'reported', headerName: 'Reported KPI', flex: 1, disableColumnMenu: true, filterable: false, renderCell: renderValueCell },
		{ field: 'est_surprise', headerName: 'Est. Surprise %', flex: 1, disableColumnMenu: true, filterable: false, renderCell: renderHighlightPercentCell },
		{ field: 'est_surprise_norm', headerName: 'Est. Surprise Norm %', flex: 1, disableColumnMenu: true, filterable: false, renderCell: renderHighlightPercentCell },

		// evaluation columns
		// { field: 'model_percent_error', headerName: 'Model % Error', flex: 1, disableColumnMenu: true, filterable: false, renderCell: renderValueCell },
		{ field: 'mae', headerName: 'MAPE', flex: 1, disableColumnMenu: true, filterable: false, renderCell: renderPercentCell },
		{ field: 'beat_miss_accuracy', headerName: 'Beat/Miss Accuracy %', flex: 1, disableColumnMenu: true, filterable: false, renderCell: renderBeatMissCell },
		// { field: 'mae_4', headerName: 'MAPE (4Q)', flex: 1, disableColumnMenu: true, filterable: false, renderCell: renderPercentCell },
		// { field: 'mae_2', headerName: 'MAPE (2Q)', flex: 1, disableColumnMenu: true, filterable: false, renderCell: renderPercentCell },
		// { field: 'mae_last_q', headerName: 'MAPE (Last Q)', flex: 1, disableColumnMenu: true, filterable: false, renderCell: renderPercentCell },
		{ field: 'corr', headerName: 'Correlation', flex: 1, disableColumnMenu: true, filterable: false, renderCell: renderCorrCell },
		{ field: 'std_dev', headerName: 'StdDev', flex: 1, disableColumnMenu: true, filterable: false, renderCell: renderPercentCell },

		// { field: 'combined_fit', headerName: 'Fit Score', flex: 1, disableColumnMenu: true, filterable: false, renderCell: renderScoreCell },
		{ field: 'est_7d_change', headerName: '7D Chng', flex: 1, disableColumnMenu: true, filterable: false, renderCell: (params: GridRenderCellParams) => renderArrowValueCell(params, props.density === 'compact') },
		// { field: 'est_3d_change', headerName: '3D Chng', flex: 1, disableColumnMenu: true, filterable: false, renderCell: (params: GridRenderCellParams) => renderArrowValueCell(params, props.density === 'compact') },
		{ field: 'quarter_complete', headerName: '% Quarter Complete', flex: 1, disableColumnMenu: true, filterable: false, renderCell: renderProgressCell },
		{ field: 'metric_sensitivity', headerName: 'Metric Sensitivity', flex: 1, disableColumnMenu: true, filterable: false, renderCell: (params: GridRenderCellParams) => renderMetricImpCell(params, props.density == 'compact') },

		{ field: 'preview_button', headerName: 'History', disableColumnMenu: true, filterable: false, renderCell: renderPreviewButton, align: 'center', hideable: false },

	];

	const hasHeader = props.title || props.ToolbarComponent;

	return (

		<>
			{hasHeader &&
				<Stack direction="row" justifyContent="space-between"
					alignItems="center" sx={{ mb: 0.5 }}>
					{props.title && <Typography>{props.title}</Typography>}

					<Stack direction="row" spacing={1}>
						{props.ToolbarComponent && props.ToolbarComponent}
					</Stack>

				</Stack>
			}



			<DataGrid
				rows={modelEstimateSummaryQuery.data || []}
				columns={columns}
				loading={modelEstimateSummaryQuery.isLoading || modelEstimateSummaryQuery.isFetching}
				hideFooter={false}
				disableSelectionOnClick={true}
				density={props.density || "standard"}
				pagination
				components={{ Toolbar: CustomToolbar }}
				rowsPerPageOptions={[100]}
				sx={{
					height: "100%",
					'& .MuiDataGrid-cell:focus-within': {
						outline: 'none'
					},
					backgroundColor: (theme) => theme.palette.card.background,

				}}
				initialState={{
					columns: {
						columnVisibilityModel: {
							// Hide columns as default:
							company: false,
							period_end: false,
							est_3d_change: false,
							est_surprise_norm: props.density === 'compact' ? false : true,
							preview_button: props.density === 'compact' ? false : true,
						},
					},
					pagination: {
						pageSize: 100
					}
				}}

			/>

			{(histDialogShow && histDialogSelection !== null) &&
				<DialogChart title={`${histDialogSelection.symbol} : ${histDialogSelection.metricName}`} isOpen={histDialogShow} handleClose={() => setHistDialogShow(false)} >

					<DataChart
						title="Daily Model Predictions (Point-in-Time)"
						chartType='line'
						dataUrl={`/model-charts/daily-model-chart-data/${histDialogSelection.metricId}/${props.field_id}/${props.model_family_id}`}
						queryKey={['model_charts', histDialogSelection.metricId, props.field_id, props.model_family_id, 'daily_data']}
						getDatasetChartType="line"
						getYAxisId="y"
						getChartColor={getModelChartColor}
						chartSelectParams={[
							// getPeriodCompareChartParam("yoy"),
							{
								name: "period_id",
								title: "FiscalQuarter",
								defaultValue: String(histDialogSelection.period_id),
								options: modelCompanyPeriodsQuery.data ? modelCompanyPeriodsQuery.data.map((period) => {
									return {
										label: period.fs_period,
										value: String(period.period_id)
									}
								}) : []
							}

						]}
						disableAnimations={true}

					/>


				</DialogChart>

			}

		</>

	)

}
