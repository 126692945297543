import AccountCircle from '@mui/icons-material/AccountCircle';
import AddIcon from '@mui/icons-material/Add';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import AppBar, { AppBarProps } from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Stack from '@mui/material/Stack';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import { styled } from '@mui/system';
import React from 'react';
import { useQuery, QueryKey, useQueryClient } from '@tanstack/react-query';
import { Link, NavigateFunction, useLocation, useNavigate, useParams } from 'react-router-dom';
import { NewDialog, AppHeaderAlertDropdown, AppHeaderSearch } from "../";
import apiClient from "../../services/apiClient";
import logo from '../../assets/logo.png';
import { useAuth0 } from "@auth0/auth0-react";
import { checkUserIsAdmin } from '../../utils/auth';

const MODEL_PATHS = ['model-features', 'company',
    'model-results', 'model-charts', 'model-settings']


const VIEWS_PATHS = ['view-summary', 'view-builder', 'view-display']

const STRATEGY_PATHS = ['strategy-builder']

const SCREENER_PATHS = ['screener']

const ACTIVECLASSNAME = "active";
const INACTIVECLASSNAME = "MuiButton-root MuiButton-text";

const NavBar = styled(AppBar)<AppBarProps>(({ theme }) => ({
    backgroundColor: theme.palette.background,
    backgroundImage: 'none',
    boxShadow: 'none',
    borderBottomWidth: 1.5,
    borderBottomStyle: 'solid',
    borderBottomColor: theme.palette.divider

}))


const AppHeaderNavButton = styled(Button)(({ theme }) => ({
    marginRight: theme.spacing(1),
    color: theme.palette.text.disabled,
    textDecoration: 'none',
    borderRadius: 0,
    '&.active': {
        color: theme.palette.text.primary,
        // borderBottomWidth: 2,
        // borderBottomStyle: 'solid',
        // borderBottomColor: theme.palette.primary.main,
    },
    ':hover': {
        color: theme.palette.text.secondary,
    }

})) as typeof Button;


function HeaderListMenu(props: {
    activeId: number,
    title: string,
    newTitle: string,
    listApiUrl: string,
    queryKey: QueryKey,
    postApiUrl: string,
    successNavigatetUrl: string,
    menuListUrl: string,
    rootPathname: string,
    pathActiveList: string[],
    navigate: NavigateFunction,
    disabled?: boolean

}) {

    const [dialogOpen, setDialogOpen] = React.useState<boolean>(false);

    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);

    const handleClose = () => {
        setAnchorEl(null);
    };
    const handleMenu = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget);
    };

    function handleDialogClose() {
        setDialogOpen(false);
    }


    async function getItems() {
        const { data } = await apiClient.get(props.listApiUrl)
        return data
    }

    const getItemsQuery = useQuery<{ id: number, name: string }[]>(props.queryKey, () => getItems());
    const queryClient = useQueryClient()

    return (
        <>
            <AppHeaderNavButton
                size="large"
                disableRipple
                aria-controls='menu-list'
                aria-haspopup="true"
                onClick={handleMenu}
                endIcon={<ArrowDropDownIcon />}
                disabled={props.disabled}
                className={(props.pathActiveList.includes(props.rootPathname))
                    ? ACTIVECLASSNAME : INACTIVECLASSNAME
                }
            >
                {props.title}
            </AppHeaderNavButton>

            <Menu
                id="menu-list"
                anchorEl={anchorEl}
                // keepMounted
                open={Boolean(anchorEl)}
                onClose={handleClose}
            >

                {
                    getItemsQuery.data && getItemsQuery.data.map((item) => {
                        return (
                            <MenuItem selected={item.id === props.activeId} key={item.id} onClick={handleClose}
                                component={Link} to={`${props.menuListUrl}${item.id}`}>
                                {item.name}
                            </MenuItem>
                        )
                    })
                }

                <Divider />

                <MenuItem onClick={() => {
                    setDialogOpen(true);
                    handleClose();
                }}
                >

                    <ListItemIcon>
                        <AddIcon color="success" />
                    </ListItemIcon>
                    New
                </MenuItem>
            </Menu>

            <NewDialog
                title={props.newTitle}
                isOpen={dialogOpen}
                handleDialogClose={handleDialogClose}
                navigate={props.navigate}
                postApiUrl={props.postApiUrl}
                successNavigatetUrl={props.successNavigatetUrl}
                onSuccess={() => queryClient.invalidateQueries(props.queryKey)}

            />

        </>
    );
}



export default function AppHeader() {


    const navigate = useNavigate();
    const { pathname } = useLocation();
    const rootPathname = pathname.split('/')[1];

    const urlParams = useParams();
    const view_id = parseInt(urlParams.view_id !== undefined ? urlParams.view_id : "0");
    const strategy_id = parseInt(urlParams.strategy_id !== undefined ? urlParams.strategy_id : "0");
    const screener_id = parseInt(urlParams.screener_id !== undefined ? urlParams.screener_id : "0");
    const queryClient = useQueryClient()

    const { user, logout } = useAuth0();

    // # check if user for useAuth0 is an admin
    const isAdminAuthed = user ? checkUserIsAdmin(user) : false;

    const [anchorElUser, setAnchorElUser] = React.useState<null | HTMLElement>(null);
    const handleOpenUserMenu = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorElUser(event.currentTarget);
    };

    const handleCloseUserMenu = () => {
        setAnchorElUser(null);
    };

    return (

        <NavBar position="static">
            <Toolbar>

                <Box
                    component="img"
                    sx={{ height: 28, mr: 3 }}
                    alt="Logo"
                    src={logo}
                />

                <Box sx={{ flexGrow: 1, display: 'flex' }}>

                    <AppHeaderNavButton
                        size="large"
                        disableRipple
                        component={Link}
                        to="/dashboard"
                        className={rootPathname === "dashboard" ? ACTIVECLASSNAME : INACTIVECLASSNAME}
                    >
                        Dashboard
                    </AppHeaderNavButton>

                    {/* <AppHeaderNavButton
                        disableRipple
                        disabled={isAdminAuthed !== true}
                        size="large"
                        component={Link}
                        to="/screener"
                        className={rootPathname === 'screener' ? ACTIVECLASSNAME : INACTIVECLASSNAME}
                    >
                        Screener
                    </AppHeaderNavButton> */}
                    <HeaderListMenu
                        activeId={screener_id}
                        title="Screener"
                        newTitle="New Screener"
                        rootPathname={rootPathname}
                        navigate={navigate}
                        pathActiveList={SCREENER_PATHS}
                        listApiUrl='/screener/'
                        queryKey={['screeners']}
                        postApiUrl='/screener/'
                        successNavigatetUrl='/screener/'
                        menuListUrl='/screener/'
                    />

                    <HeaderListMenu
                        activeId={view_id}
                        title="Views"
                        newTitle="New View"
                        rootPathname={rootPathname}
                        navigate={navigate}
                        pathActiveList={VIEWS_PATHS}
                        listApiUrl='/views/'
                        queryKey={['views']}
                        postApiUrl='/views/'
                        successNavigatetUrl='/view-builder/'
                        menuListUrl='/view-display/'
                    />

                    <AppHeaderNavButton
                        disableRipple
                        size="large"
                        component={Link}
                        to="/model-results"
                        className={(MODEL_PATHS.includes(rootPathname))
                            ? ACTIVECLASSNAME : INACTIVECLASSNAME
                        }
                    >
                        Forecast
                    </AppHeaderNavButton>

                    <HeaderListMenu
                        activeId={strategy_id}
                        title="Strategies"
                        newTitle="New Strategy"
                        rootPathname={rootPathname}
                        navigate={navigate}
                        pathActiveList={STRATEGY_PATHS}
                        listApiUrl='/strategy/'
                        queryKey={['strategies']}
                        postApiUrl='/strategy/'
                        successNavigatetUrl='/strategy-builder/performance/'
                        menuListUrl='/strategy-builder/performance/'
                    // disabled={isAdminAuthed !== true}
                    />

                    <AppHeaderNavButton
                        disableRipple
                        size="large"
                        component={Link}
                        to="/watchlist"
                        className={rootPathname === 'watchlist' ? ACTIVECLASSNAME : INACTIVECLASSNAME}
                    >
                        Watchlists
                    </AppHeaderNavButton>


                </Box>

                <Box sx={{ flexGrow: 0 }}>

                    <Stack direction="row" spacing={2}>

                        <AppHeaderSearch />

                        <AppHeaderAlertDropdown />

                        <IconButton onClick={handleOpenUserMenu} sx={{ p: 0 }}>
                            <AccountCircle />
                        </IconButton>
                        <Menu
                            sx={{ mt: '35px' }}
                            id="menu-appbar"
                            anchorOrigin={{
                                vertical: 'top',
                                horizontal: 'right',
                            }}
                            keepMounted
                            transformOrigin={{
                                vertical: 'top',
                                horizontal: 'right',
                            }}
                            anchorEl={anchorElUser}
                            open={Boolean(anchorElUser)}
                            onClose={handleCloseUserMenu}
                        >
                            {/* <MenuItem onClick={() => navigate("/profile")}>
                            <Typography>Profile Settings</Typography>
                        </MenuItem> */}
                            <MenuItem onClick={() => logout({ logoutParams: { returnTo: window.location.origin } })}>
                                <Typography>Logout</Typography>
                            </MenuItem>

                        </Menu>

                    </Stack>

                </Box>

            </Toolbar>
        </NavBar>


    );
};
