import { blue, green, orange, red } from '@mui/material/colors';
import Grid from '@mui/material/Grid';
import Stack from '@mui/material/Stack';
import { styled } from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import { alpha, Box, useTheme } from '@mui/system';
import { GridRenderCellParams } from '@mui/x-data-grid';
import React, { useState, useEffect } from 'react';
import { DataChart, DataTable, ModelUpcomingEarningsCards, MetricSensitivityCard } from "../../components";
import { getNumQuartersChartParam, getPeriodCompareChartParam, generateRangeOptions } from "../../components/Charts/chartSelectParams";
import { getIndexColor, getModelChartColor } from "../../components/Charts/utils";
import { ICompanyPeriod, useModelCompanyPeriodsQuery } from "../../api/models"

import LinearProgress from '@mui/material/LinearProgress';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import { useUserSelectionQuery, useUpdateUserSelection } from "../../api/user_selection";


const attributeIdLookup: { [key: string]: string } = {
	"1": "estimate",
	"2": "estimate_yoy",
	"3": "estimate_surprise",
	"4": "error",
	"5": "data_growth_rate",
};


const CardItem = styled("div")(({ theme }) => ({
	// height: 470,
	height: "calc(50vh - 168px)",
	padding: theme.spacing(2),
	backgroundColor: theme.palette.card.background,
	border: "1px solid",
	borderColor: theme.palette.divider,
	borderRadius: 2
}))

function formatEst(est: number) {
	if (est > 5000000) {
		est = (est / 1000000)
	}
	return est.toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}

function renderPredictionSummaryTableCell(params: GridRenderCellParams, active_field_id: number) {

	if (params.value === undefined || params.value === null) {
		return <></>;
	}

	switch (params.field) {
		case "1d_change":
		case "7d_change":
		case "est_surprise":
			return (
				<Typography sx={{ fontSize: 14, color: params.value > 0 ? green[400] : params.value < 0 ? red[400] : 'text.secondary' }} >{params.value.toFixed(1) + " %"}
				</Typography>
			);

		case "estimate":
			return (
				<Typography sx={{ fontSize: 14 }} color="text.primary"  >{formatEst(params.value)}
				</Typography>
			);

		case "corr":
			return (
				<Typography sx={{ fontSize: 14 }} color="text.primary"  >{params.value.toFixed(2)}
				</Typography>
			);

		case "std_dev":
			return (
				<Typography sx={{ fontSize: 14 }} color="text.primary"  >{params.value.toFixed(1)}%
				</Typography>
			);

		case "beat_miss_accuracy":
			return (
				<Typography sx={{ fontSize: 14 }} color="text.primary"  >{params.value.toFixed(1) + " %"}
				</Typography>
			);

		case "mae":
			return (
				<Typography sx={{ fontSize: 14 }} color="text.primary"  >{params.value.toFixed(1) + " %"}
				</Typography>
			);


		case "field":
			return (
				// field_id = 0 when combined model / field_id = -1 when consensus
				<Typography sx={{ fontSize: 12, fontWeight: params.row?.field_id === active_field_id ? 600 : 400 }}
					color={params.row?.field_id === active_field_id ? blue[400] : params.row?.field_id === -1 ? orange[400] : params.row?.is_selected ? "text.primary" : "text.secondary"}>
					{params.value}
				</Typography>
			);
		default:
			return <Typography sx={{ fontSize: 14 }} color="text.primary"  >{params.value}</Typography>;
	}

}



function renderPredictionMultiPeriodTableCell(params: GridRenderCellParams, active_field_id: number) {

	if (params.value === undefined || params.value === null) {
		return <></>;
	}

	const attribute = params.row?.attribute;
	const showPerc = ["estimate_yoy", "estimate_surprise", "error", "data_growth_rate"].includes(attribute);

	switch (params.field) {

		case "field":
			return (
				<Typography sx={{ fontSize: 12, fontWeight: params.row?.field_id === active_field_id ? 600 : 400 }}
					color={params.row?.field_id === active_field_id ? blue[400]
						: params.row?.field_id === -2 ? green[400]
							: params.row?.field_id === -1 ? orange[400]
								: params.row?.is_selected === false ? "text.secondary"
									: "text.primary"

					}>
					{params.value}
				</Typography>
			);
		default:
			if (attribute === "estimate_surprise") {
				return <Typography sx={{ fontSize: 14, color: params.value > 0 ? green[400] : params.value < 0 ? red[400] : 'text.secondary' }} >{params.value.toFixed(1) + " %"}
				</Typography>;
			}
			return <Typography sx={{ fontSize: 14 }} color="text.primary"  >{params.value.toFixed(1)}
				{showPerc && " %"}
			</Typography>;
	}

}


function PeriodSelect(props: {
	selectedPeriodId: string,
	periodOptions: ICompanyPeriod[],
	onPeriodChange: (pid: string) => void,
}) {
	return (
		<FormControl size="small">
			<Select
				value={props.periodOptions.find((period) => period.period_id === props.selectedPeriodId)?.period_id}
				variant="outlined"
				size="small"
				sx={{ mr: 1, fontSize: "small" }}
				onChange={(event: SelectChangeEvent<string>) => {
					props.onPeriodChange(event.target.value);
				}}
				MenuProps={{
					style: {
						maxHeight: 400,
					},
				}}
			>
				<MenuItem disabled value="">
					<em>Fiscal Quarter</em>
				</MenuItem>

				{props.periodOptions.map((option) => {
					return <MenuItem key={option.period_id} value={option.period_id}>{option.fs_period}</MenuItem>
				})}

			</Select>
		</FormControl>
	)
}





function EstimateCompTypeSelect(props: {
	selectedType: "single" | "multi",
	onChange: (pid: string) => void,
}) {
	return (
		<FormControl size="small">
			<Select
				value={props.selectedType}
				variant="outlined"
				size="small"
				sx={{ mr: 1, fontSize: "small" }}
				onChange={(event: SelectChangeEvent<string>) => {
					props.onChange(event.target.value);
				}}
				MenuProps={{
					style: {
						maxHeight: 400,
					},
				}}
			>
				<MenuItem disabled value="">
					<em>Table View</em>
				</MenuItem>

				<MenuItem value="single">Single Period</MenuItem>
				<MenuItem value="multi">Multi Period</MenuItem>

			</Select>
		</FormControl>
	)
}



export default function CompanyModelCharts(props: {
	company_id: number,
	metric_id: number,
	field_id: number,
	model_family_id: number,
}) {

	const modelCompanyPeriodsQuery = useModelCompanyPeriodsQuery(props.metric_id);

	const [selectedPeriodId, setSelectedPeriodId] = useState<string>("");
	const [selectedEstChartType, setSelectedEstChartType] = useState<"single" | "multi" | null>(null);

	const userSelectionQuery = useUserSelectionQuery(); // get saved user selections
	const updateUserSelection = useUpdateUserSelection(); // update saved user selections

	// useEffect, to select the first period_id by default if the period_id is not longer in the list of periods
	useEffect(() => {
		if (selectedPeriodId === "" || modelCompanyPeriodsQuery.data?.find((period) => period.period_id === selectedPeriodId) === undefined) {
			// get the first period where is_reported=false
			const defaultPeriod = modelCompanyPeriodsQuery.data?.filter((period) => period.is_reported === false)[1] || modelCompanyPeriodsQuery.data?.[0];
			setSelectedPeriodId(defaultPeriod?.period_id || "");
		}
	}, [props.metric_id, modelCompanyPeriodsQuery.data])


	useEffect(() => {

		if (userSelectionQuery.data) {

			if (userSelectionQuery.data["est_comp_chart_type"] === undefined) {
				setSelectedEstChartType("single");
			} else {

				if (userSelectionQuery.data["est_comp_chart_type"] === 1) {
					setSelectedEstChartType("single" as "single" | "multi");
				} else {
					setSelectedEstChartType("multi" as "single" | "multi");

				}
			}

		}

	}, [userSelectionQuery.data])


	if (props.metric_id === 0 || props.company_id === 0) {
		return <></>
	}

	if (userSelectionQuery.data === undefined || userSelectionQuery.isLoading || selectedEstChartType === null) {
		return <></>
	}


	return (


		<Box flexGrow={1}>

			<Stack direction="row">
				<ModelUpcomingEarningsCards metric_id={props.metric_id} field_id={props.field_id} model_family_id={props.model_family_id} />
				<MetricSensitivityCard metric_id={props.metric_id} />
			</Stack>

			< Grid container
				sx={{
					mt: 0, // note this computes to some margin ?? Don't remove.
				}}

				spacing={2}
			>

				<Grid item xs={5} >
					<CardItem>
						{/* only show after fetching new periods for this metric/company */}

						{(selectedPeriodId === "" || modelCompanyPeriodsQuery.isLoading || modelCompanyPeriodsQuery.isFetching) && <Box sx={{ width: '100%' }}>
							<LinearProgress />
						</Box>}

						{(selectedPeriodId !== "" && modelCompanyPeriodsQuery.data && modelCompanyPeriodsQuery.data.length > 0 && !modelCompanyPeriodsQuery.isLoading && !modelCompanyPeriodsQuery.isFetching) &&
							<DataChart
								title="Daily Model Predictions (Point-in-Time)"
								chartType='line'
								xAxisDate={true}
								dataUrl={`/model-charts/daily-model-chart-data/${props.metric_id}/${props.field_id}/${props.model_family_id}?period_id=${selectedPeriodId}`}
								queryKey={['model_charts', props.metric_id, props.field_id, props.model_family_id, selectedPeriodId, 'daily_data']}
								getDatasetChartType="line"
								getYAxisId="y"
								getChartColor={getModelChartColor}
								ToolbarComponent={
									<PeriodSelect
										selectedPeriodId={selectedPeriodId}
										periodOptions={modelCompanyPeriodsQuery.data}
										onPeriodChange={(pid) => { setSelectedPeriodId(pid) }}
									/>
								}

								disableAnimations={true}

							/>
						}

					</CardItem>
				</Grid>


				<Grid item xs={7}>

					<CardItem>

						{(selectedEstChartType === "single" && selectedPeriodId !== "" && modelCompanyPeriodsQuery.data && modelCompanyPeriodsQuery.data.length > 0 && !modelCompanyPeriodsQuery.isLoading && !modelCompanyPeriodsQuery.isFetching) &&

							<DataTable
								title="Estimate Comparison"
								dataUrl={`/model-charts/estimate-comp-table/${props.metric_id}/${props.model_family_id}?period_id=${selectedPeriodId}`}
								queryKey={['model_charts', props.metric_id, props.model_family_id, selectedPeriodId, 'estimate_comp']}
								renderCell={(params: GridRenderCellParams) => renderPredictionSummaryTableCell(params, props.field_id)}
								disableColumnMenu={true}
								filterable={true}

								ToolbarComponent={
									<>
										<PeriodSelect
											selectedPeriodId={selectedPeriodId}
											periodOptions={modelCompanyPeriodsQuery.data}
											onPeriodChange={(pid) => { setSelectedPeriodId(pid) }}
										/>

										<EstimateCompTypeSelect
											selectedType={selectedEstChartType}
											onChange={(ctype) => {
												setSelectedEstChartType(ctype as "single" | "multi");

												const selection = { est_comp_chart_type: ctype === "single" ? 1 : 2 };
												updateUserSelection.mutate(selection);


											}}
										/>
									</>

								}



								chartSelectParams={[

									{
										name: "show_all_fields",
										title: "Show Dataset Fields",
										// user selection only works on integers, so convert attrbute to ids
										defaultValue: String(userSelectionQuery.data['est_comp_single_show_all_fields'] || 0),
										options: [
											{ value: "0", label: "Selected Fields Only" },
											{ value: "1", label: "All Fields" },

										],
										onChange: (newValue) => {
											const selection = { est_comp_single_show_all_fields: parseInt(newValue) };
											updateUserSelection.mutate(selection);
										}
									}
								]}



							/>
						}

						{(selectedEstChartType === "multi") &&

							<DataTable
								title="Estimate Comparison - Multi Period"
								dataUrl={`/model-charts/estimate-comp-table-multi-period/${props.metric_id}/${props.model_family_id}?period_id=${selectedPeriodId}`}
								queryKey={['model_charts', props.metric_id, props.model_family_id, 'estimate_comp_multi_period']}
								renderCell={(params: GridRenderCellParams) => renderPredictionMultiPeriodTableCell(params, props.field_id)}
								disableColumnMenu={true}
								filterable={true}

								ToolbarComponent={
									<EstimateCompTypeSelect
										selectedType={selectedEstChartType}
										onChange={(ctype) => {
											setSelectedEstChartType(ctype as "single" | "multi");

											const selection = { est_comp_chart_type: ctype === "single" ? 1 : 2 };
											updateUserSelection.mutate(selection);

										}}
									/>
								}


								chartSelectParams={[
									// getNumQuartersChartParam(4, 12, 8),

									{
										name: "show_all_fields",
										title: "Show Dataset Fields",
										// user selection only works on integers, so convert attrbute to ids
										defaultValue: String(userSelectionQuery.data['est_comp_multi_show_all_fields'] || 0),
										options: [
											{ value: "0", label: "Selected Fields Only" },
											{ value: "1", label: "All Fields" },

										],
										onChange: (newValue) => {
											const selection = { est_comp_multi_show_all_fields: parseInt(newValue) };
											updateUserSelection.mutate(selection);
										}
									},


									{
										name: "num_quarters",
										title: "Display # Quarters",
										defaultValue: String(userSelectionQuery.data['est_comp_multi_period_num_q'] || 8),
										options: generateRangeOptions(4, 12, "Q"),
										onChange: (newValue) => {
											const selection = { est_comp_multi_period_num_q: parseInt(newValue) };
											updateUserSelection.mutate(selection);
										}

									},

									{
										name: "attribute",
										title: "Attribute",
										// user selection only works on integers, so convert attrbute to ids
										defaultValue: attributeIdLookup[userSelectionQuery.data['est_comp_multi_period_attribute']] || "estimate",
										options: [
											{ value: "estimate", label: "Estimate" },
											{ value: "estimate_yoy", label: "Estimate YoY%" },
											{ value: "estimate_surprise", label: "Estimate Surprise" },
											{ value: "error", label: "Error %" },
											{ value: "data_growth_rate", label: "Raw Data YoY%" },

										],
										onChange: (newValue) => {
											const attributeId = Object.keys(attributeIdLookup).find(key => attributeIdLookup[key] === newValue);
											if (attributeId) {
												const selection = { est_comp_multi_period_attribute: parseInt(attributeId) };
												updateUserSelection.mutate(selection);
											}
										}



									}
								]}

							/>


						}
					</CardItem>

				</Grid>

				<Grid item xs={5}>

					<CardItem>
						<DataChart
							title="Quarterly Predictions vs. Reported"
							chartType='line'
							dataUrl={`/model-charts/quarterly-chart-data/${props.metric_id}/${props.field_id}/${props.model_family_id}`}
							queryKey={['model_charts', props.metric_id, props.field_id, props.model_family_id, 'quarterly']}
							getDatasetChartType={(label, index) => { return (label !== "Percent Error" ? 'line' : 'bar') }}
							getYAxisId={(label, index) => { return (label !== "Percent Error" ? 'y' : 'y2') }}
							getChartColor={getModelChartColor}
							showPointOnLine={true}
							chartSelectParams={[
								getPeriodCompareChartParam("yoy"),
								getNumQuartersChartParam(4, 8, 6),
							]}
							disableAnimations={true}

						/>

					</CardItem>
				</Grid>


				<Grid item xs={2}>
					<CardItem>
						<DataChart
							title="Dataset Weights"
							chartType="doughnut"
							dataUrl={`/model-charts/data-model-weights-chart-data/${props.metric_id}`}
							queryKey={['model_charts', props.metric_id, 'model_weights']}
							getDatasetChartType="doughnut"
							getYAxisId="y"
							getChartColor={getIndexColor}
							disableAnimations={true}
						/>

					</CardItem>
				</Grid>

				<Grid item xs={5}>
					<CardItem>


						<DataChart
							title="KPI Surprise Impact"
							chartType="bar"
							dataUrl={`/model-charts/surprise-impact-chart-data/${props.metric_id}/${props.field_id}/${props.model_family_id}`}
							queryKey={['model_charts', props.metric_id, props.field_id, props.model_family_id, 'surprise_impact']}
							getDatasetChartType="bar"
							getChartColor={getModelChartColor}
							getYAxisId="y"
							// getDataColor={(value, label) => {
							// 	if (label !== 'Price Impact %') {
							// 		return getModelChartColor(label, 1)
							// 	}
							// 	return alpha(value > 0 ? green[400] : value < 0 ? red[400] : grey[400], 1)
							// }
							// }
							chartSelectParams={[
								getNumQuartersChartParam(4, 8, 4),
							]}
							disableAnimations={true}
							// getYAxisId={(label, index) => { return (label !== "Price Impact %" ? 'y' : 'y2') }}
							// getDatasetChartType={(label, index) => { return (label === "Price Impact %" ? 'bubble' : 'bar') }}
							showPointOnLine={true}

						/>
					</CardItem>
				</Grid>


			</Grid >
		</Box>


	)

}