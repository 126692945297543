import { Box, useTheme } from '@mui/system';
import {
    LineElement,
    PointElement,
    ArcElement,
    BarElement,
    CategoryScale,
    Chart as ChartJS,
    Legend,
    LinearScale,
    Title,
    Tooltip,
    LineController,
    BarController,
    DoughnutController,
    Filler

} from 'chart.js';
import annotationPlugin from "chartjs-plugin-annotation"
import LinearProgress from '@mui/material/LinearProgress';
import React, { useState } from 'react';
import apiClient from "../../services/apiClient";
import { IChartOption, IChartResponse } from "../../interfaces";
import { Chart } from 'react-chartjs-2';
import Typography from '@mui/material/Typography';
import Stack from '@mui/material/Stack';
import ChartSelect from './ChartSelect'
import ChartSelectMulti from './ChartSelectMulti'
import { useQuery, QueryKey } from '@tanstack/react-query';


ChartJS.register(
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend,
    BarElement,
    ArcElement,
    annotationPlugin,
    LineController,
    BarController,
    DoughnutController,
    Filler

);


function useDataQuery(dataUrl: string, queryKey: QueryKey, params: URLSearchParams, enabled: boolean) {
    // combine user passed queryKey, and params to create a unique queryKey
    const queryKeyCombined = [...queryKey, Object.fromEntries(params)];

    async function getData() {

        const { data } = await apiClient.get(
            dataUrl,
            {
                params: params
            }
        );


        return data
    }

    return useQuery<IChartResponse>(queryKeyCombined,
        () => getData(),
        { enabled: enabled || true, refetchOnWindowFocus: false, refetchOnMount: true, staleTime: Infinity });

}


export default function DataChart(props: {
    title: string,
    dataUrl: string,
    queryKey: QueryKey,
    chartType: "line" | "bar" | "pie" | "doughnut",
    getChartColor: (label: string, index: number) => string,
    getYAxisId: string | ((label: string, index: number) => string),
    getDatasetChartType: string | ((label: string, index: number) => string),
    chartSelectParams?: IChartOption[],
    displayLegend?: boolean,
    displayXAxis?: boolean,
    disableAnimations?: boolean,
    getDataColor?: (value: number, label: string) => string,
    indexAxis?: string,
    showPointOnLine?: boolean,
    ToolbarComponent?: JSX.Element,
    queryEnabled?: boolean,
    labelsFilter?: (legendItem: any, chartData: any) => boolean,
    legendOnClick?: (event: any, legendItem: any, legend: any) => void,
    xAxisDate?: boolean,

}) {

    const theme = useTheme();
    const chartSelectParams = props.chartSelectParams || []; // default prop to empty list to map below.
    const displayLegend = props.displayLegend === undefined ? true : props.displayLegend; // default prop to true
    const displayXAxis = props.displayXAxis === undefined ? true : props.displayXAxis; // default prop to true
    const disableAnimations = props.disableAnimations === undefined ? false : props.disableAnimations; // default prop to true
    const getDataColor = props.getDataColor === undefined ? (val: number, label: string) => "white" : props.getDataColor; // default
    const isArcElement = ["pie", "doughnut"].includes(props.chartType);

    // param option for view displaying charts
    // get default params to default state
    let defaults: { [key: string]: string } = {};
    chartSelectParams.forEach((param: IChartOption) => {
        defaults[param.name] = param.defaultValue;
    });

    const [params, setParams] = useState<URLSearchParams>(new URLSearchParams({ ...defaults }));

    // add select query params to query key to re-fetch data on change.
    const query = useDataQuery(props.dataUrl, props.queryKey, params, props.queryEnabled === undefined ? true : props.queryEnabled);

    if (query.isLoading || query.isFetching) {
        return (
            <Box sx={{ width: '100%' }}>
                <LinearProgress />
            </Box>
        );
    }


    const chartDatasets = query.data?.datasets.map((x, i) => ({
        ...x,
        yAxisID: typeof props.getYAxisId === "string" ? props.getYAxisId : props.getYAxisId(x.label, i),

        // if arc alement (i.e. pie, doughnut) we pass a single dataset with an array of colors
        // for line/bar types, we pass a single color for the entire line
        borderColor: isArcElement ? x.data.map((val, idx) => props.getChartColor(x.label, idx)) : props.getDataColor ? x.data.map((val) => getDataColor(val, x.label)) : props.getChartColor(x.label, i),
        backgroundColor: isArcElement ? x.data.map((val, idx) => props.getChartColor(x.label, idx)) : props.getDataColor ? x.data.map((val) => getDataColor(val, x.label)) : props.getChartColor(x.label, i),
        // backgroundColor: x.data.map((i) => i > 0 ? "green" : "red"),

        type: typeof props.getDatasetChartType === "string" ? props.getDatasetChartType : props.getDatasetChartType(x.label, i) as any,
        pointRadius: props.showPointOnLine ? 2 : 0,
        borderWidth: 2,
        fill: x.fill || false,
    }));

    const options = {
        indexAxis: props.indexAxis || 'x',
        responsive: true,
        maintainAspectRatio: false,
        plugins: {
            legend: {
                display: displayLegend,
                position: 'top' as const,
                labels: {
                    color: theme.palette.text.primary,
                    boxHeight: 5,
                    boxWidth: isArcElement ? 10 : 30,
                    ...(props.labelsFilter && { filter: props.labelsFilter })
                },
                ...(props.legendOnClick && { onClick: props.legendOnClick }),

            },
            title: {
                display: false,
                // text: 'Daily Model Prediction (QTD)',
                // color: theme.palette.text.primary,
                // font: { size: 14.8, weight: "400", lineHeight: 1.75 },
                // align: 'start' as const,
                // padding: 2
            },
            tooltip: {
                mode: 'index',
                position: 'average',
                intersect: isArcElement ? true : false,
                filter: function (tooltipItem: any, data: any) {
                    // filter any null values from tooltip
                    var dataVal = tooltipItem.dataset.data[tooltipItem.dataIndex];
                    return !isNaN(dataVal) && dataVal !== null;
                }
            },



            // hover: {
            //     mode: 'nearest' as any,
            //     // intersect: false
            // },
            // interaction: {
            //     mode: 'x'
            // }


            annotation: {
                annotations: query.data?.annotations || []
            },

        },

        scales: {
            x: {

                ...(props.xAxisDate && { type: 'time', parsing: false, time: { unit: 'day', tooltipFormat: 'M/d/yyyy' } }),

                grid: {
                    display: false,
                    drawOnChartArea: true,
                    color: theme.palette.divider
                },
                ticks: {
                    display: !isArcElement && displayXAxis,
                    color: theme.palette.text.secondary,
                    font: {
                        size: 10
                    }
                }

            },
            y: {
                grid: {
                    display: !isArcElement,
                    color: theme.palette.divider
                },
                ticks: {
                    display: !isArcElement,
                    color: theme.palette.text.primary,
                    fontSize: 6,
                },

            },

        },

        animation: {
            duration: disableAnimations ? 0 : 800 // general animation time

        },
        responsiveAnimationDuration: 0, // animation duration after a resize


    };



    return (

        <Box
            sx={{
                height: "90%",
            }}
        >

            <Stack direction="row" sx={{ mb: 1 }} justifyContent="space-between">

                <Typography>{props.title}</Typography>

                <Stack direction="row" alignItems={"flex-start"}>

                    {
                        chartSelectParams.map((select: IChartOption, index) => {

                            if (select.multi === true) {
                                return (
                                    <ChartSelectMulti
                                        key={index}
                                        menuTitle={select.title}
                                        options={select.options}
                                        value={[...params.getAll(select.name)]}
                                        setValue={(values) => {
                                            // const searchParamArray = new URLSearchParams(val.map((x) => [select.name, x]));
                                            // params.set(select.name, searchParamArray.toString());
                                            params.delete(select.name);

                                            // for each value in values, add to params
                                            values.forEach((val) => {
                                                params.append(select.name, val);
                                            });

                                            setParams((new URLSearchParams(params)));
                                        }}
                                    />
                                )

                            }
                            return (
                                <ChartSelect
                                    key={index}
                                    menuTitle={select.title}
                                    options={select.options}
                                    value={params.get(select.name) || ""}
                                    setValue={(val) => {
                                        params.set(select.name, val);
                                        setParams((new URLSearchParams(params)));

                                        select.onChange && select.onChange(val);
                                    }}
                                />
                            );


                        })
                    }


                    {props.ToolbarComponent && props.ToolbarComponent}

                </Stack>


            </Stack>

            {(query.data && query.data?.datasets?.length > 0) && <Chart type={props.chartType} options={options} data={{ datasets: chartDatasets || [], labels: query.data.labels }} />}
            {query.data?.datasets?.length === 0 && <Typography variant="body2" color="text.secondary" sx={{ textAlign: "center" }}>No data to display</Typography>}
        </Box>

    );

}