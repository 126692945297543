import apiClient from "../services/apiClient";
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import {
	GridColumns,
} from '@mui/x-data-grid';
import { ICreateInput } from "../interfaces";


interface IScreener {
	id: number;
	name: string;
	watchlist_id?: number | null;
}


interface ITableData {
	[key: string]: string | object;
}

type TableDataResponse = { rows: ITableData[], columns: GridColumns<ITableData> };


interface IScreenerInput {
	id: number;
	user_input_id: number;
	screener_id: number;
	name: string;
	type: string;
	underlying_id: number;
	params?: object;
	source_name?: string;
}


export function useScreenerDataQuery(screener_id: number, watchlist_id: number | null, enabled: boolean = true) {

	async function getScreenerData() {

		if (watchlist_id === null || watchlist_id === 0) {
			const { data } = await apiClient.get(`/screener/data/${screener_id}`);
			return data;
		} else {
			const { data } = await apiClient.get(`/screener/data/${screener_id}?watchlist_id=${watchlist_id}`);
			return data;
		}

	}

	return useQuery<TableDataResponse>(['screener_data', screener_id, watchlist_id], () => getScreenerData(),
		{ enabled: screener_id !== 0 && enabled, refetchOnWindowFocus: false, refetchOnMount: false })
}



export function useScreenerQuery(screener_id: number) {

	async function getScreener() {

		const { data } = await apiClient.get(`/screener/${screener_id}`);
		return data;
	}

	return useQuery<IScreener>(['screener', screener_id], () => getScreener(),
		{ enabled: screener_id !== 0, refetchOnWindowFocus: false, refetchOnMount: false })
}

export function useScreenersQuery() {

	async function getScreeners() {

		const { data } = await apiClient.get(`/screener/`);
		return data;
	}

	return useQuery<IScreener[]>(['screeners'], () => getScreeners(),
		{ refetchOnWindowFocus: false, refetchOnMount: false })
}


export function useAddScreenerInput(screener_id: number) {

	async function addScreenerInput(addedInput: ICreateInput) {
		const { data } = await apiClient.post(`/screener/input/${screener_id}`, addedInput);
		return data
	}

	const queryClient = useQueryClient()

	return useMutation((addedInput: ICreateInput) =>
		addScreenerInput(addedInput),
		{

			onSuccess: () => {
				queryClient.invalidateQueries(['screener_inputs', screener_id]);
				queryClient.invalidateQueries(['screener_data', screener_id]);

			},

		}
	)

}


export function useRemoveScreenerInput(screener_id: number) {

	async function removeScreenerInput(input_id: number) {
		const { data } = await apiClient.delete(`/screener/input/${screener_id}/${input_id}`);
		return data
	}

	const queryClient = useQueryClient()

	return useMutation((input_id: number) =>
		removeScreenerInput(input_id),
		{

			// optimistic update
			onMutate: async input_id => {
				// Cancel any outgoing refetches (so they don't overwrite our optimistic update)
				await queryClient.cancelQueries(['screener_inputs', screener_id])

				// Snapshot the previous value
				const previousInputs = queryClient.getQueryData(['screener_inputs', screener_id])

				// Optimistically update to the new value
				queryClient.setQueryData(['screener_inputs', screener_id], (old: any) => {
					return old.filter((e: any) => e.id !== input_id);
				})
				// Return a context object with the snapshotted value
				return { previousInputs }
			},

			onSuccess: () => {
				queryClient.invalidateQueries(['screener_inputs', screener_id]);
				queryClient.invalidateQueries(['screener_data', screener_id]);
			},

		}
	)

}


export function useScreenerInputsQuery(screener_id: number) {

	async function getScreenerInputs() {

		const { data } = await apiClient.get(`/screener/input/${screener_id}`);
		return data;
	}

	return useQuery<IScreenerInput[]>(['screener_inputs', screener_id], () => getScreenerInputs(),
		{ refetchOnWindowFocus: false, refetchOnMount: false })
}




export function useRemoveScreener() {

	async function removeScreener(screener_id: number) {
		const { data } = await apiClient.delete(`/screener/${screener_id}`);
		return data
	}

	const queryClient = useQueryClient()

	return useMutation((screener_id: number) =>
		removeScreener(screener_id),
		{
			onSuccess: () => {
				queryClient.invalidateQueries(['screeners']);
			},

		}
	)

}
export function useAddScreenerWatchlist(screener_id: number) {

	async function addScreenerWatchlist(watchlist_id: number) {
		const { data } = await apiClient.post(`/screener/watchlist/${screener_id}/${watchlist_id}`);
		return data
	}

	const queryClient = useQueryClient()

	return useMutation((watchlist_id: number) =>
		addScreenerWatchlist(watchlist_id),
		{

			// optimistic update
			onMutate: async watchlist_id => {
				// Cancel any outgoing refetches (so they don't overwrite our optimistic update)
				await queryClient.cancelQueries(['screener', screener_id])

				// Snapshot the previous value
				const previousScreener = queryClient.getQueryData(['screener', screener_id])

				// Optimistically update the watchlist_id to the new selected watchlist
				queryClient.setQueryData(['screener', screener_id], (old: any) => {
					return { ...old, watchlist_id }
				}
				)
				// Return a context object with the snapshotted value
				return { previousScreener }
			},



			onSuccess: () => {
				queryClient.invalidateQueries(['screener', screener_id]);
			},
		}
	)

}


export function useRemoveScreenerWatchlist(screener_id: number) {

	async function removeScreenerWatchlist() {
		const { data } = await apiClient.delete(`/screener/watchlist/${screener_id}`);
		return data
	}

	const queryClient = useQueryClient()

	return useMutation(() =>
		removeScreenerWatchlist(),
		{
			onMutate: async () => {
				// Cancel any outgoing refetches (so they don't overwrite our optimistic update)
				await queryClient.cancelQueries(['screener', screener_id])

				// Snapshot the previous value
				const previousScreener = queryClient.getQueryData(['screener', screener_id])

				// Optimistically update the watchlist_id to the new selected watchlist
				const watchlist_id = null;
				queryClient.setQueryData(['screener', screener_id], (old: any) => {
					return { ...old, watchlist_id }
				}
				)
				// Return a context object with the snapshotted value
				return { previousScreener }
			},


			onSuccess: () => {
				queryClient.invalidateQueries(['screener', screener_id]);
			},
		}
	)

}